@import "../node_modules/bootstrap/scss/functions";
@import "../node_modules/bootstrap/scss/variables";

$font-family-sans-serif: "Nunito Sans", Arial;

$dropdown-link-hover-bg: #82ba00;

$enable-responsive-font-sizes: true;

$table-bg-scale: -80%;

$theme-colors: (
  "charcoal": #444444,
  "brown": #e4e7d4,
  "green": #82ba00,
  "blue": #337ab7,
  "yellow": #f0ad4e,
  "red": #d9534f,
  "primary": #0d6efd,
  "secondary": #6c757d,
  "success": #28a745,
  "info": #17a2b8,
  "warning": #ffc107,
  "danger": #dc3545,
  "light": #f8f9fa,
  "dark": #343a40,
  "grayish": #666666,
);

$table-variants: (
  "primary": scale-color($primary, $table-bg-scale),
  "secondary": scale-color($secondary, $table-bg-scale),
  "success": scale-color($success, $table-bg-scale),
  "info": scale-color($info, $table-bg-scale),
  "warning": scale-color($warning, $table-bg-scale),
  "danger": scale-color($danger, $table-bg-scale),
  "light": $light,
  "dark": $dark,
  "white": #fff,
);

body {
  font-size: 0.99rem !important;
}

.pt-4-1 {
  padding-top: ($spacer * 1.65);
}

.pt-4-2 {
  padding-top: ($spacer * 1.8);
}

.pt-4-3 {
  padding-top: ($spacer * 1.95);
}

.pt-4-4 {
  padding-top: ($spacer * 1.1);
}

.pt-4-5 {
  padding-top: ($spacer * 1.25);
}

.dropdown-item {
  font-size: 0.99rem !important;
}

.menu-dropdown-toggle::after {
  content: none !important;
}

.menu-notification::after {
  content: none !important;
}

@media screen and (min-width: 576px) {
  .has-mega-menu .container-sm {
    width: 540px;
  }
}

@media screen and (min-width: 768px) {
  .has-mega-menu .container-md {
    width: 720px;
  }
}

@media screen and (min-width: 992px) {
  .has-mega-menu .container-lg {
    width: 960px;
  }
}

@media screen and (min-width: 1200px) {
  .has-mega-menu .container-xl {
    width: 1140px;
  }
}

.loading-page {
  height: 97vh;
  width: 97vw;
}

.intl-tel-input {
  width: 100%;
}

.vh-90 {
  height: 90vh;
}

.vh-80 {
  height: 80vh;
}

.vh-75 {
  height: 75vh;
}

.badge-wrapper {
  position: relative;
}

.notification-badge {
  position: absolute;
  top: 0;
  right: 0;
  border-radius: 50% !important;
}

.no-resize {
  resize: none;
}

.form-label.required:after {
  content: "*";
  color: red;
}

.accordion .card {
  overflow: visible !important;
}

input[data-readonly] {
  pointer-events: none;
}

@media print {
  .tableexport-caption {
    display: none;
  }
}

.rdtPickerOpenUpwards .rdtPicker {
  top: auto;
  bottom: 100%;
}

.addon-requirements {
  padding: 0.5rem 0;

  ul {
    margin-bottom: 0;
    background-color: #d3d3d3;
    border-radius: 0.25rem;
    padding-top: 1rem;
    padding-bottom: 1rem;

    &:before {
      content: "Requirements";
      font-weight: bold;
      font-size: 1.2rem;
      margin-bottom: 0.5rem;
      text-decoration: underline;
    }
  }
}

@import "../node_modules/bootstrap/scss/bootstrap";
